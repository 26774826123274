<template>
    <v-dialog v-model="exibirModalLogReserva" width="80vw" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        <v-icon large>mdi-eye</v-icon> Log da Reserva {{ logNumeroReserva }} 
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalLogReserva()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            
       
            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('reserva') }" data-cy="btn-log-email" >Log de Reserva
            </v-btn>

            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('email') }" data-cy="btn-log-email" >Log de E-mail
            </v-btn>

            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('integracao')}" data-cy="btn-log-email" >Log de Integracao
            </v-btn>

                <v-card-text>

                <v-data-table :headers="headers" :items="loglist"
                    v-if="verLogReserva  && loglist && loglist.length > 0" class="elevation-1" no-data-text="Nenhum registro encontrado no log de reservas." >
                    <!-- <template v-slot:[`item.datahoraevento`]="{ item }">
                        <span style="color:#1976d2;" data-cy="td-datahoraevento">{{item.datahoraevento | formatarDataYYHora}}</span>
                    </template>                  -->
                </v-data-table>


                <v-data-table
                    :headers="headersLogEmail"
                    :items="loglistEmail"
                    v-if="verLogEmail  && loglistEmail && loglistEmail.length > 0"
                    class="elevation-1"
                    no-data-text="Nenhum registro encontrado no log de emails."
                >
                    <!-- <template v-slot:[`item.datahoraenvio`]="{ item }">
                        <span style="color:#1976d2;" data-cy="td-datahoraenvio">{{item.datahoraenvio | formatarDataYYHora}}</span>
                    </template>                  -->
                </v-data-table>

                <v-data-table
                    :headers="headersLogIntegracao"
                    :items="logIntegracao"
                    v-if="verLogIntegracao && logIntegracao && logIntegracao.length > 0"
                    class="elevation-1"
                    no-data-text="Nenhum registro encontrado nos logs de integração."
                >
                    <template v-slot:[`item.datahorarequisicao`]="{ item }">
                        <span style="color:#1976d2;" data-cy="td-datahorarequisicao">{{item.datahorarequisicao | formatarDataYYHora}}</span>
                    </template>                 
                </v-data-table>
            </v-card-text>

            
        </v-card>
    </v-dialog>
</template>          

<script>


export default {
    data: () => ({
        logIntegracao:[],
        verLogEmail:false,   
        verLogIntegracao:false,   
        verLogReserva:false,   
        loglist: [],
        headers: [
            { text: "Data", value: "datahoraevento", width: "120px", },
            { text: "Evento", value: "desceventolog", width: "120px", sortable: false},
            { text: "Usuário", value: "apelido", width: "120px" }
        ],
        loglistEmail: [],
        headersLogEmail: [
            { text: "Data", value: "datahoraenvio", width: "120px", },
            { text: "Email", value: "to", width: "120px", sortable: false},
            { text: "Assunto", value: "subject", width: "120px" }
        ],
        headersLogIntegracao: [
            { text: "Data", value: "datahorarequisicao", width: "200px", },
            { text: "Evento", value: "evento", width: "200px", sortable: false},
            { text: "Dados", value: "dados", width: "200px", sortable: false},
        ]
    }),
    props:["exibirModalLogReserva", "logNumeroReserva", "idReserva","idHospede", "emailReserva"],
    components:{
        
    },
    methods: {
        setVerLog(tipo){

            if(tipo === 'reserva'){

                this.verLogReserva = true 
                this.verLogEmail = false 
                this.verLogIntegracao = false 
                this.carregar()
            }

            if(tipo === 'email'){
      
                this.verLogEmail = true 
                this.verLogReserva = false 
                this.verLogIntegracao = false 
                this.carregarLogEmails()
            }

            if(tipo === 'integracao'){

                this.verLogIntegracao = true 
                this.verLogEmail = false 
                this.verLogReserva = false 
                this.carregarLogIntegracao()
            }

        },
        setModalLogReserva(){
            this.$emit('setModalLogReserva')
        },
        carregar() {
            this.$http.post(`/logsistema/logreservaauxiliar`,{idReserva:this.idReserva, idHospede:this.idHospede})
            .then((response) => {
                this.loglist = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        carregarLogEmails() {

            this.$http.post(`/logsistema/logemailreserva`,{emailReserva:this.emailReserva})
            .then((response) => {
                this.loglistEmail = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        },
        carregarLogIntegracao() {
            this.$http.post(`/logsistema/logintegracao`,{idReserva:this.idReserva, idHospede:this.idHospede,numeroreserva:this.logNumeroReserva})
            .then((response) => {
                this.logIntegracao = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
    mounted() {
        this.carregar()
        this.carregarLogEmails()
        this.carregarLogIntegracao()
    },
};
</script>