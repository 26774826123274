<template>
  <v-card style="width: 70vw!important; height: 80vh!important;">
    <v-toolbar color="blue">
      <span style="width:95%">Documentos</span>
      <span style="width:5%;padding:10px;"> 
            <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Captura de Documentos"/>
      </span>
      <span style="width:5%"> 
          <v-btn x-small color="red" @click="$emit('fechar')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
          </v-btn>
      </span>
    </v-toolbar>
    <div style="color:orange;padding-left:10px;text-align:center;"><span v-if="!possuiEmpresaReservante">Bloqueado para edição: sem empresa reservante.</span></div>
    <v-card-text style="">

      <div style="display:flex;align-items:center;justify-content:center;">
        <div style="background-color:gree;display:flex;flex-direction:column;align-items:center;">
          <img v-if="imgselfie" :src="'data:image.png;base64,' + imgselfie" class="imgUpload" style="cursor: pointer;" @click="setModalZoomParameter(imgselfie)" />
          <v-btn  v-if="imgselfie" small elevation="" color="error" style="margin-top:10px;margin-left:50px;margin-right:50px;" @click="exibirConfirmacaoRemocao('selfie','Selfie')">Remover</v-btn>
        </div>
        <div style="background-color:gree;display:flex;flex-direction:column;align-items:center;">
            <img v-if="imgSrcFrente" :src="'data:image.png;base64,' + imgSrcFrente" class="imgUpload" style="cursor: pointer;" @click="setModalZoomParameter(imgSrcFrente)" />
            <v-btn v-if="imgSrcFrente" small elevation="" color="error" style="margin-top:10px;width:80px;" @click="exibirConfirmacaoRemocao('docfrente','Frente do Doc')">Remover</v-btn>
        </div>
        <div style="background-color:gree;display:flex;flex-direction:column;align-items:center;">
            <img v-if="imgSrcVerso" :src="'data:image.png;base64,' + imgSrcVerso" class="imgUpload"   style="cursor: pointer;" @click="setModalZoomParameter(imgSrcVerso)" />
            <v-btn v-if="imgSrcVerso" small elevation="" color="error" style="margin-top:10px;margin-left:50px;margin-right:50px;" @click="exibirConfirmacaoRemocao('docverso','Verso do Doc')">Remover</v-btn>
        </div>
        <div style="background-color:gree;display:flex;flex-direction:column;align-items:center;">
            <img v-if="imgSrcAdicional" :src="'data:image.png;base64,' + imgSrcAdicional" class="imgUpload"   style="cursor: pointer;" @click="setModalZoomParameter(imgSrcAdicional)" />
            <v-btn v-if="imgSrcAdicional" small elevation="" color="error" style="margin-top:10px;margin-left:50px;margin-right:50px;" @click="exibirConfirmacaoRemocao('docadicional','Documento Adicional')">Remover</v-btn>
        </div>
      </div>

      <div style="display:flex;justify-content:space-between">
        <div style="height:100px;display:flex;justify-content:center;align-items:center;">
            <v-btn v-if="btnSalvarHabilidado && possuiEmpresaReservante" color="blue" v-on:click="solicitarCapturaSelfie()" style="color:white;font-weight:bold;width:200px;font-size:14px!important;">OCR SELFIE</v-btn>
        </div>

        <div style="height:100px;display:flex;justify-content:center;align-items:center;">
            <v-btn v-if="btnSalvarHabilidado && possuiEmpresaReservante" color="blue" v-on:click="solicitarCapturaDoc()" style="color:white;font-weight:bold;width:200px;font-size:14px!important;">OCR Docs</v-btn>
        </div>

        <div style="height:100px;display:flex;justify-content:center;align-items:center;">
          <v-btn 
            @click="toggleDocs(1)"
            style="height:40px;border-radius:5px;background-color:#00457b;color:white;font-weight:bold;width:200px;display:flex;justify-content:center;align-items:center;"
          >
            Upload Doc</v-btn>
          <!-- <label v-if="btnSalvarHabilidado && possuiEmpresaReservante" for="file-upload" 
            style="height:40px;border-radius:5px;background-color:#00457b;color:white;font-weight:bold;width:200px;display:flex;justify-content:center;align-items:center;"
          >
            <span style="font-size:14px!important;font-weight:bold;color:white;">Upload Doc Frente</span>
          </label>    
          <input id="file-upload" type="file" style="display:none;" accept="image/*" 
            @change="onFileChangeFrente" /> -->
        </div>

        <!-- <div style="height:100px;display:flex;justify-content:center;align-items:center;">
          <v-btn 
            @click="toggleDocs(1)"
            style="height:40px;border-radius:5px;background-color:#00457b;color:white;font-weight:bold;width:200px;display:flex;justify-content:center;align-items:center;"
          >
            Upload Doc Verso</v-btn> -->
          <!-- <label v-if="btnSalvarHabilidado && possuiEmpresaReservante"  for="file-upload-verso" 
            style="height:40px;border-radius:5px;background-color:#00457b;color:white;font-weight:bold;width:200px;display:flex;justify-content:center;align-items:center;"
          >
            <span style="font-size:14px!important;font-weight:bold;color:white;">Upload Doc Verso</span>
          </label>
          <input id="file-upload-verso" type="file" style="display:none;" accept="image/*" 
            @change="onFileChangeVerso" /> -->
        <!-- </div> -->
      </div>

    </v-card-text>    

    <ModalZoomImagem :imgZoom="imgZoom" :exibirModalZoom="exibirModalZoom" @setModalZoom="()=>setModalZoom()" />

    <v-dialog v-model="uploadDocs" 
      width="1000" max-width="70vw">
      
      <CapturaDocumentoTipo v-if="passo == 1" @setarPasso="(valor) => setPasso(valor)" 
        @setarTipoDocSelecionado="(valor) => setTipoDoc(valor)"
        @fechar="toggleDocs()"
      ></CapturaDocumentoTipo>

      <CapturaDocumentoFrente v-if="passo == 2" @setarPasso="(valor) => setPasso(valor)" 
        :tipoDocSelecionado="tipoDoc" :idHospede="idHospede" 
        :idReserva="idReserva" :idReservaHospede="idReservaHospede" :docfrente="docfrente"
        @fechar="toggleDocs()" @update="updateImage()"
      ></CapturaDocumentoFrente>

      <CapturaDocumentoVerso v-if="passo == 3" @setarPasso="(valor) => setPasso(valor)" 
        :tipoDocSelecionado="tipoDoc" :idHospede="idHospede" 
        :idReserva="idReserva" :idReservaHospede="idReservaHospede" :docverso="docverso"
        @fechar="toggleDocs()" @update="updateImage()"
        @avancar="avancar()"
      ></CapturaDocumentoVerso>

    </v-dialog>

    <template>
      <v-row justify="center">
        <v-dialog
          v-model="confirmarSubstituicao"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-title class="text-h5">
              Já existe uma pessoa com o mesmo CPF:
            </v-card-title>
            <v-card-text>
              <strong>{{this.dadosOutraPessoa.nome+' '+this.dadosOutraPessoa.sobrenome}}</strong> <br>
              <strong>Deseja utilizar os seus dados?</strong>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="naoSubstituir()"
                data-cy="btn-dadospnaosub"
              >
                Não
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="substituirPessoa()" 
                data-cy="btn-dadospsub"
              >
                Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>


    <template>
      <v-row justify="center">
        <v-dialog
          v-model="confirmarRemocaoDoc"
          persistent
          max-width="600"
        >
          <v-card>
            <v-card-title class="text-h5">
              Confirmar Remoção do {{this.textoDocSelecionadoRemocao}} ?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="confirmarRemocaoDoc = false"
                data-cy="btn-dadospnaosub"
              >
                Não
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="removerImagem(docSelecionadoRemocao)" 
                data-cy="btn-dadospsub"
              >
                Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

  </v-card>
</template>
<script>

import {serializarImagem} from '../../utils/serializar'

import ModalZoomImagem from '../DashViews/Modals/ModalZoomImagem'
import CapturaDocumentoTipo from './CapturaDocumento/CapturaDocumentoTipo.vue'
import CapturaDocumentoFrente from './CapturaDocumento/CapturaDocumentoFrente.vue'
import CapturaDocumentoVerso from './CapturaDocumento/CapturaDocumentoVerso.vue'
import BotaoTutorial from '../material/BotaoTutorial.vue';

export default {
  name: "CapturaDocumento",
  data: () => ({
    nome:null,
    ladodocnome:null,
    principal:null,
    ladodocfk:null,
    image: '',
    imgSrc:'',
    imgSrcFrente:'',
    imgSrcVerso:'',
    imgSrcAdicional:'',
    imgselfie:'',
    isImg:false,
    exibirModalZoom:false,
    imgZoom:null,
    btnSalvarHabilidado:true,
    uploadDocs: false,
    passo: 1,
    tipoDoc: 'RG',
    confirmarSubstituicao: false,
    substituir: false,
    dadosOutraPessoa: false,
    confirmarRemocaoDoc:false,
    docSelecionadoRemocao:null,
    textoDocSelecionadoRemocao:null,
  }),
  components: {
    ModalZoomImagem,
    CapturaDocumentoTipo,
    CapturaDocumentoFrente,
    CapturaDocumentoVerso,
    BotaoTutorial
  },
  props: ["id", "idHospede", "idReservaHospede", "idReserva", 
    "possuiEmpresaReservante", "docfrente", "docverso", "idhospedepms"
  ],
  methods : {
    naoSubstituir() {
      this.substituir = false
      this.confirmarSubstituicao = false
      this.$emit('avancar')
    },
    substituirPessoa() {
      this.substituir = true
      this.confirmarSubstituicao = false
      // Salvar os dados substituindo a pessoa
      let params = {
        reserva: {
          id: this.idReserva,
          hotel: localStorage.getItem("hotel")
        },
        hospede: {
          id: this.idHospede,
          idhospedepms: this.idhospedepms,
          substituir: this.substituir,
          documentos: [
            {
              tipo: this.dadosOutraPessoa.tipo,
              documento: this.dadosOutraPessoa.documento,
            }
          ],
        },
      };
      this.$http
        .post("/reserva/upinsert", params)
        .then((response) => {          
          if (response.status == 200) {
            this.$emit('update')
            this.$emit('avancar')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateImage() {
      this.listarDados()
      this.$emit('update')
    },
    avancar(){
      this.$socket.emit('captura_to_dash_avancar', {
        usuario: localStorage.getItem('useremail'), 
        msg: ''
      })  
    },
    toggleDocs(passo) {
      this.uploadDocs = !this.uploadDocs
      if (passo) {
        this.passo = passo
      }
    },
    setTipoDoc(valor) {
      this.tipoDoc = valor
    },
    setPasso(valor) {
      this.passo = valor;
      if (valor==0) this.toggleDocs(0);
    },
    async setModalZoomParameter(imgParaZoom){
      this.imgZoom = await imgParaZoom
      this.setModalZoom()
    },
    setModalZoom(){
        if(this.exibirModalZoom){ this.imgZoom = null;}
        this.exibirModalZoom = !this.exibirModalZoom
    },

    solicitarCapturaDoc(){
        let objeto = {
                          usuario: localStorage.getItem('useremail'), 
                          msg: '',
                          idHospede: this.idHospede,
                          idReserva: this.idReserva,
                          idReservaHospede: this.id
                    }
        let tag = localStorage.getItem('tag')
        if(tag){
          objeto.tag = tag
        }
        this.$socket.emit('dash_to_leitor_solicitar_captura_doc', objeto)  
    },

    solicitarCapturaSelfie(){

        this.$socket.emit('dash_to_leitor_solicitar_captura_selfie', {
                          usuario: localStorage.getItem('useremail'), 
                          msg: '',
                          idHospede: this.idHospede,
                          idReserva: this.idReserva,
                          idReservaHospede: this.id,
                          nome:this.nome,
                    })  
    },

    onFileChangeFrente(e) {
        this.ladodocnome = "DocumentoFrente"
        this.ladodocfk = "docfrente"
        // this.setImage(e)
        serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaLogo(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
    },
    onFileChangeVerso(e) {
        this.ladodocnome = "DocumentoVerso"
        this.ladodocfk = "docverso"
        // this.setImage(e)
        serializarImagem(e, 0.5).then(resp =>{
                this.imgSrc = resp.dados
                this.salvaLogo(resp.tipo);
            }).catch(error =>{
                this.loading = false
                this.snack = true
                this.snackColor = "error" 
                this.snackText = error.message
            })
    },
    async setImage(e) {
      const file = e.target.files[0];

        if (!file.type.includes("image/")) {
            alert("Please select an image file");
            return;
        }

        if (typeof FileReader === "function") {
            const reader = new FileReader();

            reader.onload = (event) => {
            this.isImg = true;
            this.imgSrc = event.target.result;
            let index = event.target.result.indexOf(",") + 1;
            this.imgSrc = event.target.result.substring(index,event.target.result.length);
            if(this.imgSrc){
                this.salvaLogo(file.type)
            }
            };
            reader.readAsDataURL(file);
        } else {
            alert("Sorry, FileReader API not supported");
        }
    },

    async salvaLogo(tipoImg) {
        if (!this.imgSrc.length > 0) {
            console.log("invalido");
            return;
        }

        let dados =   {
                        "token":'3c8478c895580214e0ff389448054854',
                        "tokenInfo":{"usuario":{"id":localStorage.getItem('userid')} },
                        "tipo" : tipoImg,
                        "nome" : this.ladodocnome,
                        "dados" : this.imgSrc,
                        "tabela": "hospede",
                        "fk": this.ladodocfk,
                        "chave": "id",
                        "valor": this.idHospede
                    }
        await this.$http.post("/imagem/upinsertAuthSimples",dados,
            {timeout:60000 }
            )
            .then((response) => {
                if(response.status == 200){
                    if(this.ladodocfk == 'docfrente'){
                      this.imgSrcFrente = this.imgSrc
                    }else{
                      this.imgSrcVerso = this.imgSrc
                    }
                    console.log("Documento Frente Salvo com Sucesso!");
                    this.$emit('update')
                }else{
                    console.log("Não foi possivel salvar Documento Frente!")
                }
            })
            .catch((error) => {
            console.log(error.response);
            });
    },
    exibirConfirmacaoRemocao(tipo,texto){
      this.docSelecionadoRemocao = tipo
      this.textoDocSelecionadoRemocao = texto
      this.confirmarRemocaoDoc = true
    },
    removerImagem(tipo){
      
      
      this.$http.post("/reserva/removerImagem", {coluna:tipo,idhospede:this.idHospede})
            .then(async () =>{
                  this.confirmarRemocaoDoc = false
                  await this.listarDados()

            })
            .catch(error =>{ 
                this.confirmarRemocaoDoc = false
                console.error(JSON.stringify(error)) 
            })

      
    },
    listarDados() {
      this.imgSrcFrente = null
      this.imgSrcVerso = null
      this.imgSrcAdicional = null
      if(this.idHospede){
        this.$http
          .post("/reserva/select", {
            hotel: localStorage.getItem("hotel"),
            idhospede: this.idHospede,
            idreserva:this.idReserva
          })
          .then((response) => {
            this.nome = response.data[0].nome + ' '+response.data[0].sobrenome 
            this.btnSalvarHabilidado = !response.data[0].enviadopms
            this.principal = response.data[0].principal
            //imagem frontal
            this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: response.data[0].docfrente,})
                .then(async (responseimg) => {
                  // this.imgSrcFrente = String.fromCharCode.apply(null,responseimg.data[0].dados.data)
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgSrcFrente =  await Buffer.from(responseimg.data[0].dados).toString()
                  }else{
                    this.imgSrcVerso = null;
                  }
                })
                .catch((error) => { this.imgSrcVerso = null; console.log(error); });

            // imagem verso
            this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: response.data[0].docverso,})
                .then((responseimg) => {
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgSrcVerso = Buffer.from(responseimg.data[0].dados).toString()
                  }else{
                    this.imgSrcVerso = null;
                  }
                })
                .catch((error) => { this.imgSrcVerso = null;console.log(error); });

            // imagem doc adicional
            this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: response.data[0].docadicional,})
                .then((responseimg) => {
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgSrcAdicional = Buffer.from(responseimg.data[0].dados).toString()
                  }else{
                    this.imgSrcAdicional = null;
                  }
                })
                .catch((error) => { this.imgSrcAdicional = null;console.log(error); });

            //imagem selfie
            this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"),id: response.data[0].selfie,})
                .then(async (responseimg) => {
                  // this.imgSrcFrente = String.fromCharCode.apply(null,responseimg.data[0].dados.data)
                  if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this.imgselfie =  await Buffer.from(responseimg.data[0].dados).toString()
                  }else{
                    this.imgselfie = null;
                  }
                })
                .catch((error) => { this.imgselfie = null; console.log(error); });


          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

  

  },
  watch: {
    idHospede() {
      this.exibirModalZoom = false
      this.imgZoom = null
      this.listarDados()
    },
  },
  mounted(){
    this.exibirModalZoom = false
    this.imgZoom = null
    this.listarDados();
    this.sockets.subscribe("avancar", () => {
        console.log("AVANCANDO")
        this.$emit('avancar')
    });

    this.sockets.subscribe("confirmarsubstituir", (dados) => {
        console.error(`Retorno: ${JSON.stringify(dados)}`)
        this.confirmarSubstituicao = true
        this.dadosOutraPessoa = dados.data
    });

  }

};
</script>

<style scoped>
.imgUpload{
        max-width:200px;
        max-height: 200px;
        max-width:90vw;
        margin-top:20px;
        margin-left:20px;
        margin-right:20px;
    }
</style>