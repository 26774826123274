<template>
 <div class="wout-login">
    <div class="center" v-if="componenteatual === 'selecionardocs'">
      <span class="confirmar">Para melhor garantir a segurança desta transação, selecione um documento para tirar uma foto, Ok?</span>
        <button class="continuar" v-for="(doc, index) in documentos" :key="index" @click="() => selecionarDoc(doc)">
          <span>{{ doc.descricao}}</span>
          <v-icon large class="icone-continuar">mdi-chevron-right</v-icon>
        </button>
    </div>
    <v-dialog v-model="modalAlerta" width="80vw" max-width="600px">
      <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding: 10px;justify-content: center;align-items: center;text-align: center;">
        <h1 style="color: #1560E5; margin-top: 5px; font-size: 20px!important;">{{ docSelecionado.descricao}}</h1>
        <p style="color: #1560E5; margin-top: 5px;">Iniciar captura das fotos?</p>
        <div style="display: flex; justify-content: space-around; width: 100%;">
          <button class="confirmar" style="background-color: #1560E5; width: 40%; margin-top: 5px;padding: 5px;" @click="() => this.modalAlerta = false">Cancelar</button>
          <button class="confirmar" style="background-color: #1560E5; width: 40%; margin-top: 5px;padding: 5px;" @click="confirmarDoc">Confirmar</button>
        </div>
      </div>
    </v-dialog>

    <CapturarFotoDoc v-if="componenteatual === 'capturarFotos'" :dados="docSelecionado" @etapasConcluidas="() => this.$emit('etapasConcluidas')"/>

    <ConfirmarFotoCapturada v-if="componenteatual === 'visualizarFoto'"/>

    </div>
</template>

<script>
  import CapturarFotoDoc from './CapturarFotosDoc.vue';
  import ConfirmarFotoCapturada from './ConfirmarFotoCapturada.vue';
 export default {
  components: {
    CapturarFotoDoc,
    ConfirmarFotoCapturada
  },
  data() {
    return {
      documentos: [],
      modalAlerta: false,
      docSelecionado: '',
      componenteatual: 'selecionardocs',
    }
  },
  methods: {
    async requisitarDocumentos() {
      try {
        const result = await this.$http.post("/webcheckout/getDocumentos", {}, { headers: {'x-access-token': localStorage.getItem('checkout-token')}});
        this.documentos = result.data;
      } catch (error) {
        console.log(error)
      }
    },

    selecionarDoc(doc) {
      this.docSelecionado = doc;
      this.modalAlerta = true;
    },

    confirmarDoc() {
      this.modalAlerta = false;
      this.componenteatual = 'capturarFotos'
    }
  },

  async  mounted() {
    await this.requisitarDocumentos();
  }
 }
</script>

<style scoped>
.root {
  background-color: #1560E5;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
}
.confirmar {
    text-align: center;
    width: 65vw;
    font-weight: normal;
    color: white!important;
    padding: 10px;
}
.continuar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 70vw;
    max-width: 300px;
    color: white;
    background-color: rgb(60,140,241)!important;
    border-radius: 15px;
    margin-bottom: 15px;
}

.confirmar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-weight: normal;
    width: 70vw;
    max-width: 300px;
    color: white;
    border-radius: 15px;
    margin-bottom: 15px;
}
.icone-continuar {
    font-weight: bold;
    color:#1560e5!important;
}

</style>